<template>
  <section class="profile">
    <div class="page-header">
      <h3 class="page-title">
        GeoJSON Process
      </h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><router-link :to="{name: 'upload-statuses'}">Upload Statues</router-link></li>
          <li class="breadcrumb-item active" aria-current="page">{{ process.id }}</li>
        </ol>
      </nav>
    </div>
    <div class="row">
    <div class="col-12 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <b-tabs class="tab-solid tab-solid-primary">
            <b-tab active>
              <template slot="title">
                <i class='mdi mdi-buffer'></i> Summary
              </template>
                <div class="row">
                  <div class="col-md-6 grid-margin stretch-card">
                    <div class="card">
                      <div class="card-body">
                        <span class="d-block mb-3">
                          <strong>ID</strong>
                        </span>
                         <span class="d-block mb-3">
                          <strong>Name</strong>
                        </span>
                        <span class="d-block mb-3">
                          <strong>Complete</strong>
                        </span>
                        <span class="d-block mb-3">
                          <strong>Error</strong>
                        </span>
                        <span class="d-block mb-3">
                          <strong>Tileset</strong>
                        </span>
                        <span class="d-block mb-3">
                          <strong>Owner</strong>
                        </span>
                        <span class="d-block mb-3">
                          <strong>Progress</strong>
                        </span>
                        <span class="d-block mb-3">
                          <strong>Created</strong>
                        </span>
                        <span class="d-block mb-3">
                          <strong>Modified</strong>
                        </span>
                    </div>
                  </div>
                </div>

                  <div class="col-md-6 grid-margin stretch-card">
                    <div class="card">
                      <div class="card-body">
                        <span class="d-block mb-3">
                           {{ process.id }}
                        </span>
                         <span class="d-block mb-3">
                          {{ process.name }}
                        </span>
                        <span class="d-block mb-3">
                          {{ process.complete }}
                        </span>
                        <span class="d-block mb-3">
                          {{ process.error || "NA" }}
                        </span>
                        <span class="d-block mb-3">
                          {{ process.tileset }}
                        </span>
                        <span class="d-block mb-3">
                          {{ process.owner }}
                        </span>
                        <span class="d-block mb-3">
                          {{ process.progress }}
                        </span>
                        <span class="d-block mb-3">
                          {{ process.created }}
                        </span>
                        <span class="d-block mb-3">
                          {{ process.modified }}
                        </span>
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
    </div>
  </section>
</template>

<script>

//import StarRating from 'vue-star-rating'
import helper from '../../util/helper.js'

export default {
  name: 'Process',
  data() {
    return {
      process: {}
    }
  },
  computed: {
  },
  components: {
    //StarRating
  },
  methods: {
    formatTime(time, format) {
      return helper.formatTime(time, format)
    }
  },
  created() {
    this.$store.dispatch("plan/fetchUploadProcess", {upload_id: this.$route.params.id})
    .then(res => {
       this.process = res.data.data
    })
  }
}
</script>
